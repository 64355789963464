<template>
  <div id="hy">
    <touTop :headername="headerName" />
    <div class="mis">
      <div class="left">
        <sider :headername="headerName" :sideMenus="sideMenu"  @transkey="getKey"/>
      </div>
       <router-view/>
      <!-- <div class="right" style="padding: 2vh">
        <breadCrumb :siderContent="siderName" />
        <div class="alltop">
          <span>时间： </span>
          <a-range-picker
            @change="onChange"
            :placeholder="['开始日期', '结束日期']"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
          <span style="margin-left: 1.5vh">
            <a-select default-value="订单状态" @change="handleChange" allowClear placeholder="订单状态">
              <a-select-option
                v-for="(item, index) in items"
                :key="index"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </span>
          <a-input class="ipts" placeholder="请输入订单号" v-model="order_no" />
          <a-input
            class="ipt"
            placeholder="查询挂账人请输入手机号"
            v-model="hang_mobile"
          />
          <button class="bTn" @click="searchMember">查询</button>

          <br />
        </div>
        <div>
          <a-table
            :columns="columns"
            :data-source="members"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            style="width: 100%; margin-top: 1vh"
            :pagination="{
              current: current,
              total: total,
              pageSize: 10,
            }"
            @change="handleTableChange"
          >
            <span slot="status" slot-scope="text">
              <span v-if="text === 10"> 未结算</span>
              <span v-if="text === 20"> 已结算</span>
              <span v-if="text === 60"> 已退款</span>
              <span v-if="text === 30"> 已结算-挂账</span>
            </span>
            <span slot="way" slot-scope="text, record">
              <div v-for="(item, index) in record.paylog" :key="index">
                <span>{{ item.type_desc }}</span>
                <span v-if="item.payed_price">({{ item.payed_price }})</span>
              </div>
            </span>
            <span slot="action" slot-scope="text, record" class="gray">
              <span
                :class="[record.is_anti === 0 ? 'active' : 'isclick', 'button']"
                @click="changeUncheckout(record)"
              >
                <span> 反结账 </span>
                <span class="heng" v-if="record.is_anti === 0">————</span>
              </span>
              <span
                :class="[
                  record.is_refund === 0 ? 'active' : 'isclick',
                  'button',
                ]"
                @click="refundHandle(record)"
              >
                <span> 退款 </span>
                <span class="heng" v-if="record.is_refund === 0">———</span>
              </span>
              <span
                :class="[record.is_hang === 0 ? 'active' : 'isclick', 'button']"
                @click="changeWriteoff(record)"
              >
                <span> 销账 </span>
                <span class="heng" v-if="record.is_hang === 0">———</span>
              </span>
              <a-divider type="vertical" />
              <a class="clicks"> 打印 </a>
              <a class="click" @click="clickDetail(record)"> 详情 </a>
            </span>
            <span slot="order_clerk" slot-scope="text, record">
              <div v-for="(item, index) in record.order_clerk" :key="index">
                {{ item }}
              </div>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <uncheckoutModal
      :isShow="showUncheckout"
      :id="order_id"
      @choose="changeUn(arguments)"
    />
    <writeoffModal
      :isShow="showWriteoff"
      :id="order_id"
      @choose="changeWriteo(arguments)"
      :da="record"
    />
    <ruleModal />
    <paymentModal />
    <drawbackModal
      :isShow="showDrawback"
      :da="record"
      @choose="changeModal"
      @changemo="changeModalStatu"
    />
    <refundModal @choose="clooseRefund" v-if="showRefund" :isShow="showRefund" :orderData="record"/> -->
    </div>
  </div>
</template>
<script>
// import { billList } from "@/request/bill.js";
// import { refundOrderDetail } from '@/request/bill'
import sider from "@/components/sider/sider";
import touTop from "@/components/touTop/touTop";
// import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
// import uncheckoutModal from "./modules/uncheckoutModal";
// import writeoffModal from "./modules/writeoffModal";
// import ruleModal from "./modules/ruleModal";
// import paymentModal from "./modules/paymentModal";
// import drawbackModal from "./modules/drawbackModal";
// import refundModal from "./modules/refundModal";
// import Flow from "./modules/flow.vue"
// const columns = [
//   {
//     title: "桌号",
//     dataIndex: "table_name",
//     ellipsis: true,
//   },
//   {
//     title: "订单号",
//     dataIndex: "order_no",
//     ellipsis: true,
//   },
//   {
//     title: "实收",
//     dataIndex: "payed_price",
//     ellipsis: true,
//   },
//   {
//     title: "时间",
//     dataIndex: "order_time",
//     ellipsis: true,
//   },
//   {
//     title: "状态",
//     dataIndex: "pay_status",
//     ellipsis: true,
//     scopedSlots: { customRender: "status" },
//   },
//   {
//     title: "操作",
//     dataIndex: "operate",
//     ellipsis: true,
//     scopedSlots: { customRender: "action" },
//   },
// ];
export default {
  data() {
    return {
      // showUncheckout: false, // 反结账modal
      // showWriteoff: false, //销账modal
      // showDrawback: false, //详情modal
      // showRefund: false,  //退款
      // members: [],
      // items: [
      //   { key: 20, name: "已结算" },
      //   { key: 10, name: "待结算" },
      //   { key: 60, name: "已退款" },
      //   { key: 30, name: "已结算-挂账" },
      // ],
      // columns,
      headerName: "账单流水",
      sideMenu: [
          { key: 0, value: "订单管理" },
          { key: 1, value: "交易明细" },
          { key: 2, value: "退货单" },
        ],
      // siderName: "账单流水", //sider切换内容
      // clickEveryGood: "",
      // keywords: "",
      // formLayout: "horizontal",
      // form: this.$form.createForm(this, { name: "coordinated" }),
      // current: 1, //表格当前页
      // total: 0, //表格数据条数
      // order_no: "", //订单号
      // start_time: "", //开始时间
      // end_time: "", //结算时间
      // pay_status: "", //订单状态
      // order_id: "", //订单id
      // record: "", //每条订单的数据
      // hang_mobile: "", //挂账人手机号
      // start_timeSure:'',//最终开始时间
      // end_timeSure:'',//最终结算时间
      // pay_statusSure:'',//最终订单状态
      // order_noSure: "", //最终订单号
      // hang_mobileSure: "", //最终挂账人手机号


    };
  },
  components: {
    touTop,
    sider,
    // breadCrumb,
    // uncheckoutModal, //反结账
    // writeoffModal, //挂账
    // ruleModal,
    // paymentModal,
    // drawbackModal, //详情modal
    // refundModal,
    // Flow,
  },
  mounted() {
    // this.billListRequest();
  },
  updated() {
    // this.showDrawback
  },
  methods: {
    // changeModalStatu() {
    //   this.showWriteoff = true;
    // },
    // onChange(date, dateString) {
    //   this.start_time = dateString[0];
    //   this.end_time = dateString[1];
    // },
    // changeUncheckout(record) {
    //   //点击反结账
    //   if (record.is_anti === 1) {
    //     this.showUncheckout = true;
    //   }
    //   this.order_id = record.order_id;
    // },
    // refundHandle(record){
    //   //退款
    //   console.log('tuikuan ');
      
    //   if(record.is_refund == 1){
        
    //     this.record = record;
    //     this.showRefund = true
    //   }
    // },
    // clooseRefund(){
    //   this.showRefund = false
    //   this.billListRequest();
    // },
    // changeWriteoff(record) {
    //   //点击销账
    //   this.record = record;
    //   this.order_id = record.order_id;
    //   if (record.is_hang === 1) {
    //     this.showWriteoff = true;
    //   }
    // },
    // clickDetail(record) {
    //   //点击详情
    //   console.log(JSON.stringify(record));
      
    //   if(record.pay_status ==60 ){
    //     refundOrderDetail({order_id: record.order_id}).then(res => {
    //         if(res.data.status == 200){
    //           this.record = record;
    //           this.showDrawback = true;
    //         }else{
    //           this.$message.error(res.data.message)
    //         }
    //       })
    //   }else{
    //     this.record = record;
    //     this.showDrawback = true;
    //   }
    // },
    // changeUn(number) {
    //   //关闭反结账modal
    //   if (number[0]) {
    //     this.showUncheckout = false;
    //   }
    //   if (number[1]) {
    //     this.billListRequest();
    //   }
    // },
    // changeWriteo(number) {
    //   // 关闭销账modal
    //   console.log(number);
    //   if (number[0]) {
    //     this.showWriteoff = false;
    //   }
    //   if (number[1]) {
    //     this.billListRequest();
    //   }
    // },
    // changeModal() {
    //   this.showDrawback = false;
    // },
    // handleChange(value) {
    //   this.pay_status = value;
    // },
    // billListRequest() {
    //   //列表请求
    //   const _this = this;
    //   billList({
    //     listRows: 10,
    //     page: _this.current,
    //     order_no: _this.order_noSure,
    //     start_time: _this.start_timeSure,
    //     end_time: _this.end_timeSure,
    //     pay_status: _this.pay_statusSure,
    //     hang_mobile: _this.hang_mobileSure,
    //   })
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         const list = res.data.data.list && res.data.data.list;
    //         _this.total = res.data.data.list.total && res.data.data.list.total;
    //         const members = res.data.data.list.data && res.data.data.list.data;
    //         members.map((item) => {
    //           item.operate = {
    //             is_hang: item.is_hang,
    //             is_refund: item.is_refund,
    //             is_anti: item.is_anti,
    //           };
    //         });
    //         _this.members = members;
    //         // console.log(JSON.stringify(members))
    //       }
    //     })
    //     .catch((result) => {
    //       return false;
    //     });
    // },
    // handleTableChange(e) {
    //   //点击分页
    //   this.current = e.current;
    //   this.billListRequest();
    // },
    // searchMember() {
    //   this.current = 1;
    //   this.start_timeSure=this.start_time,//最终开始时间
    //   this.end_timeSure=this.end_time,//最终结算时间
    //   this.pay_statusSure=this.pay_status,//最终订单状态
    //   this.order_noSure=this.order_no, //最终订单号
    //   this.hang_mobileSure=this.hang_mobile, //最终挂账人手机号
    //   this.billListRequest();
    // },
     getKey(key) { //获取sider值
      if(key===0){
         this.$router.push({path: '/bill/flow',query: {key: key}})
      };
      if(key===1){
         this.$router.push({path: '/bill/detail',query: {key: key}})
      };
      if(key===2){
         this.$router.push({path: '/bill/return',query: {key: key}})
      };
     
      // this.siderNumber=key;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mis {
  width: 100%;
  display: flex;
  height: 95.4vh;
}
.left {
  width: 17vw;
  background: rgba(46, 62, 78, 1);
}

</style>
